import Vue from "vue";
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

import ApiPlugin from "./plugins/api";
import { VBTooltipPlugin } from 'bootstrap-vue';

Vue.use(VBTooltipPlugin)
Vue.use(ApiPlugin);
Vue.use(BootstrapVueIcons);

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/dayjs";
import "@/libs/vue-select";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
