export default {
	namespaced: true,
	state: {
		courierMap: {},
		mapOrders: [],

		count: 0,
		loading: false,

		filters: {
			date: null,
			city: null,
			office: null,
			courierMap: null,
		},

		curPage: 1,
		countPerPage: 10,
	},
	getters: {
		getMapOrders: (state) => {
			return state.mapOrders;
		},
		getPrintOrder: (state) => {
			return state.courierMap.print_order;
		},
		getCount: (state) => {
			return state.count;
		},
		getCurPage(state) {
			return state.curPage;
		},
		getCountPerPage: (state) => {
			return state.countPerPage;
		},
		getFilters: (state) => {
			return state.filters;
		},
		getLoading: (state) => {
			return state.loading;
		},
	},
	mutations: {
		setCourierMap(state, payload) {
			if (!payload) {
				state.courierMap = null;
				state.filters.courierMap = null;
			} else {
				state.courierMap = payload;
				state.filters.courierMap = payload.id;
			}
		},
		setMapOrders(state, payload) {
			state.mapOrders = payload;
		},
		swapOrders(state, payload) {
			state.courierMap.orders = payload.map((id) => state.courierMap.orders.find((order) => order.id === id));

			if (state.filters.date) {
				state.mapOrders = state.courierMap.orders.filter((item) => item.delivery_date === state.filters.date);
			}
			if (state.filters.city) {
				state.mapOrders = state.courierMap.orders.filter((item) => item.recipient_city.id === state.filters.city);
			}
		},
		removeOrder(state, payload) {
			state.courierMap.orders = state.courierMap.orders.filter(o => o.id !== payload.id);
			state.mapOrders = state.mapOrders.filter(o => o.id !== payload.id);
			state.courierMap.print_order = state.courierMap.print_order.filter(o => o !== payload.id);
		},
		setCount(state, payload) {
			state.count = payload;
		},
		changePage(state, payload) {
			state.curPage = payload;
		},
		resetFilters(state) {
			state.filters = {
				date: null,
				city: null,
				office: null,
				courierMap: null,
			};
		},
		resetPagination(state) {
			state.curPage = 1;
		},
		changeLoading(state, payload) {
			state.loading = payload;
		},
	},
	actions: {
		// fetchMaps({ commit, state }) {
		//     commit('changeLoading', true);
		//     commit('resetData');

		//     this._vm.$api.couriers.getCourierMaps({
		//         ...state.filters,
		//         offset: ((state.curPage - 1) * state.countPerPage),
		//         limit: state.countPerPage
		//     }).then((response) => {
		//         commit('setMaps', response.data.results)
		//         commit('setCount', response.data.count)
		//     }).finally(() => {
		// 		commit('changeLoading', false)
		// 	});
		// },
		fetchMap({ commit, state }, idMap) {
			commit("changeLoading", true);

			this._vm.$api.couriers
				.getCourierMap(idMap)
				.then((response) => {
					if (response.data.orders.length === 0) {
						response.data.print_order = [];
					} else if (!response.data.print_order || response.data.print_order.length === 0) {
						response.data.print_order = response.data.orders.map((order) => order.id);
					} else {
						response.data.orders = response.data.print_order.map((id) =>
							response.data.orders.find((order) => order.id === id),
						);
					}
					commit("setCourierMap", response.data);

					let filteredOrders = response.data.orders;

					if (state.filters.date) {
						filteredOrders = filteredOrders.filter((item) => item.delivery_date === state.filters.date);
					}
					if (state.filters.city) {
						filteredOrders = filteredOrders.filter((item) => item.recipient_city.id === state.filters.city);
					}
					commit("setMapOrders", filteredOrders);
				})
				.finally(() => {
					commit("changeLoading", false);
				});
		},
		changeOrdersOrder({ commit, state }, { idMap, oldIndex, nextIndex }) {
			commit("changeLoading", true);
			const data = {
				print_order: [...state.courierMap.print_order],
			};
			const switchedElemIndex = state.courierMap.orders.findIndex((order) => order.id === state.mapOrders[nextIndex].id);
			const id = data.print_order.splice(oldIndex, 1)[0];
			data.print_order = [
				...data.print_order.slice(0, oldIndex < nextIndex ? switchedElemIndex : switchedElemIndex),
				id,
				...data.print_order.slice(oldIndex < nextIndex ? switchedElemIndex : switchedElemIndex),
			];

			this._vm.$api.couriers
				.editCourierMap(idMap, data)
				.then((response) => {
					commit("swapOrders", data.print_order);
				})
				.finally(() => {
					commit("changeLoading", false);
				});
		},
		removeOrder({ commit, state }, { id }) {
			commit("changeLoading", true);
			const print_order = [...state.courierMap.print_order];
			const orders = [...state.courierMap.orders];
			const orderIndex = state.courierMap.orders.findIndex(o => o.id === id);
			const printOrderIdIndex = state.courierMap.print_order.findIndex(elem => elem === id);
			orders.splice(orderIndex, 1);
			print_order.splice(printOrderIdIndex, 1);
			this._vm.$api.couriers
				.editCourierMap(
					state.courierMap.id,
					{
						print_order,
						orders: orders.map(order => order.id)
					}
				)
				.then((response) => {
					commit("removeOrder", { id });
				})
				.finally(() => {
					commit("changeLoading", false);
				});
		},
		resetPagination({ commit, state }) {
			commit("resetPagination");
		},
		resetFilters({ commit, state }) {
			commit("resetFilters");
		},
	},
};
