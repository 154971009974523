export const RoleConstants = {
	CR: "CR",
	AD: "AD",
	LG: "LG",
	OP: "OP",
	CC: "CC",
	SM: "SM",
};

const role = {
	CR: "Курьер",
	AD: "Администратор",
	LG: "Логист",
	OP: "Оператор",
	CC: "Сотрудник колл-центра",
	SM: "Кладовщик",
};

export default role;
